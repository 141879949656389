import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Table from '../Table/Table';
import Accordion from '../Accordion/Accordion';
import Link from '../Link/Link';

const getComponentForNode = (node) => {
  const type = node.data.target.__typename;

  switch (type) {
    case 'ContentfulAccordion':
      const { title, openByDefault, accordionContent } = node.data.target; 
      return (
        <Accordion title={title} openByDefault={openByDefault}>
          <RichText content={accordionContent} />
        </Accordion>
      );
    case 'ContentfulTable':
      const { table: { tableData: rows } } = node.data.target;
      return (
        <Table rows={rows} />
      );
    default:
      return null;
  }
};

export const DEFAULT_OPTIONS = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData, description } = node.data.target;
      return (
        <GatsbyImage image={getImage(gatsbyImageData)} alt={description} />
      );
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return (
        <h2 className="text-4xl mb-4 font-bold text-gray-900">
          {children}
        </h2>
      );
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return (
        <h3 className="text-3xl mb-4 font-semibold text-gray-900">
          {children}
        </h3>
      );
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return (
        <h4 className="text-2xl mt-6 pb-2 border-b border-gray-200 mb-4 font-semibold text-gray-900">
          {children}
        </h4>
      );
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return (
        <h4 className="text-xl pb-2 border-b border-gray-100 mb-4 font-semibold text-gray-900">
          {children}
        </h4>
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <p className="text-lg mb-2 text-gray-900 leading-loose">
          {children}
        </p>
      );
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <ul className="list-disc ml-10 mb-5">{children}</ul>
      );
    },
    [BLOCKS.OL_LIST]: (node, children) => {
      return (
        <ol className="list-decimal ml-10 mb-5">
          {children}
        </ol>
      );
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return (
        <li>{children}</li>
      );
    },
    [BLOCKS.HR]: (node, children) => {
      return (
        <hr className="my-6" />
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      return getComponentForNode(node);
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <Link className="text-gray-900 underline" to={node.data.uri}>
          {children}
        </Link>
      );
    }
  }
};

const RichText = ({ content, options }) => {
  const rendered = React.useMemo(() => {
    return renderRichText(content, options);
  }, [options, content]);

  return (
    <React.Fragment>
      {rendered}
    </React.Fragment>
  );
};

RichText.defaultProps = {
  options: DEFAULT_OPTIONS
};

export default RichText;
