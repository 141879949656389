import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import Link from '../Link/Link';
import RichText, { DEFAULT_OPTIONS } from '../Shared/RichText';

const ALERT_QUERY = graphql`
  query AlertQuery {
    contentfulAlertBar {
      id
      barColorHexCode
      textColorHexCode
      isVisible
      text {
        raw
      }
    }
  }
`;

const getAlertBarRichTextOptions = (paragraphColor) => {
  return {
    renderNode: {
      ...DEFAULT_OPTIONS.renderNode,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <p
            className="text-white m-0 p-0 text-sm"
            style={{ color: paragraphColor }}
          >
            {children}
          </p>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <Link
            to={node.data.uri}
            style={{ color: paragraphColor, textDecoration: 'underline' }}
          >
            {children}
          </Link>
        );
      },
    },
  };
};

const TopHeader = () => {
  const { contentfulAlertBar: alertBar } = useStaticQuery(ALERT_QUERY);

  const richTextOptions = React.useMemo(() => {
    return getAlertBarRichTextOptions(alertBar.textColorHexCode)
  }, [alertBar.textColorHexCode]);

  if (!alertBar.isVisible) {
    return null;
  }

  return (
    <div className="flex py-3 px-8 sm:px-10 bg-navy-base justify-center">
      <RichText content={alertBar.text} options={richTextOptions} />
    </div>
  );
};

export default TopHeader;
