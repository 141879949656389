import React from 'react';

export const useClassNames = (...classes) => {
  const computedClass = React.useMemo(() => {
    return classes.reduce((computed, nextClass) => {
      return `${computed} ${nextClass}`;
    }, '');

  }, [classes]);

  return computedClass;
};