import React from 'react';
import PropTypes from 'prop-types';
import { useLockScroll } from '../../../utils/Scroll';

const Modal = ({ title, description, children, onRequestClose, padded, animated }) => {
  useLockScroll();

  return (
    <React.Fragment>
      <div className="fixed top-0 left-0 right-0 bottom-0 z-50">
        <button
          className="absolute top-0 left-0 right-0 bottom-0 bg-gray-500 opacity-75 cursor-default"
          onClick={onRequestClose}
        >
          <span className="screen-reader">Close</span>
        </button>
        <div
          className="absolute flex justify-center items-center max-w-md w-full"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <div
            className={`bg-white rounded-lg overflow-hidden ${padded ? 'p-6' : ''} text-center`}
            data-sal={animated ? 'slide-up' : ''}
            data-sal-duration="500"
            data-sal-delay="0"
            data-sal-easing="ease"
          >
            {title && (
              <h3 className="text-2xl leading-6 font-bold text-gray-900 mb-3">
                {title}
              </h3>
            )}
            {description && (
              <p className="text-base leading-5 text-gray-500 mb-9">
                {description}
              </p>
            )}
            {children}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

Modal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any,
  onRequestClose: PropTypes.func,
  padded: PropTypes.bool
};

Modal.defaultProps = {
  onRequestClose: () => {},
  padded: true,
  animated: false,
};

export default Modal;
